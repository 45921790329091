import Content from './Content/Content';
import HeaderText from './HeaderText/HeaderText';
import PageFooter from './PageFooter/PageFooter';
import { useState } from "react";
import CommercialOffer from './../../../CommonComponents/CommercialOffer/CommercialOffer';

function Workbooks14() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div >
            <CommercialOffer isOpen={isOpen} setIsOpen={setIsOpen} startProduct="Тетрадь. Робототехника 1-4 классы" />
            <HeaderText />
            <Content />
            <PageFooter setIsOpen={setIsOpen} />
        </div>
    );
}


export default Workbooks14;
