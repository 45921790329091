import styles from './MainPage.module.css'
import CompanyDescription from './CompanyDescription/CompanyDescription';
import Projects from './Projects/Projects';
import Stack from './Stack/Stack';
import Services from './Services/Services';
import Clients from './Clients/Clients';
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MainPage(props) {
    let navigate = useNavigate();
    const ClientsRef = useRef(null);
    const ServicesRef = useRef(null);
    const { state } = useLocation();
    const [isService] = useState(
        state?.["isService"]
    );

    useEffect(() => {
        if (isService) {
            ServicesRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
            navigate('/');
        }

    }, [isService]);

    useEffect(() => {
        props.setServicesRef(ServicesRef); 
    }, [ServicesRef]);


    return (
        <div>
            <CompanyDescription />
            <Projects />
            <Stack />
            <div ref={ServicesRef}>
                <Services ClientsRef={ClientsRef} />
            </div>
            <div ref={ClientsRef}>
                <Clients />
            </div>
        </div>
    );
}


export default MainPage;
