import styles from './ProjectContainer.module.css'
import { useNavigate } from "react-router-dom";

function ProjectContainer() {
    let navigate = useNavigate();
    return (
        <div className={styles.projectJustifier}>
            <div className={styles.projectContainerWrapper}>
                <div className={styles.projectHolder}>
                    <div className={styles.threeProjectBox}>
                        <div className={styles.leftBox} onClick={()=>{navigate("/projects/robo_logo");  window.scrollTo(0, 0);}}> 
                            <div className={styles.linkArrowBox}>   
                                <div className={styles.linkArrowJustifier}>   
                                    <div className={styles.linkArrow}/>
                                </div>
                            </div>
                            <div className={styles.leftBoxText}>
                                <div className={styles.boxTitle}>
                                    Программно-аппаратный комплекс логопедической поддержки «Платформа речевого развития РобоЛого»
                                </div>
                                <div className={styles.boxSubTitle}>
                                    для детей дошкольного и младшего школьного возраста с использованием технологий ИИ
                                </div>  
                            </div>
                        </div>
                        <div className={styles.rightBox} >
                            <div className={styles.topRightBox} onClick={()=>{navigate("/projects/mobitest");  window.scrollTo(0, 0);}}>
                                <div className={styles.linkArrowBox}>   
                                    <div className={styles.linkArrowJustifier}>   
                                        <div className={styles.linkArrow}/>
                                    </div>
                                </div>
                                <div className={styles.topRightBoxText}>
                                    <div className={styles.boxTitle}>
                                        Обновленное приложение MobiTEST для проведения онлайн уроков и лабораторных работ (В разработке)
                                    </div>
                                    <div className={styles.boxSubTitle}>
                                        в котором учителя сами могут опубликовать свои курсы и просматривать работы других авторов
                                    </div>  
                                </div>
                            </div>
                            <div className={styles.bottomRightBox} onClick={()=>{navigate("/projects/voice_dialogue_system");  window.scrollTo(0, 0);}}>
                                <div className={styles.linkArrowBox}>   
                                    <div className={styles.linkArrowJustifier}>   
                                        <div className={styles.linkArrow}/>
                                    </div>
                                </div>
                                <div className={styles.bottomRightBoxText}>
                                    <div className={styles.boxTitle}>
                                        Программное обеспечение системы речевого диалога (СРД)
                                    </div>
                                    <div className={styles.boxSubTitle}>
                                        В конце 2022 года был успешно закрыт проект по заказной разработке для компании ООО "Прикладная Робототехника" на 20+ млн. рублей.
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProjectContainer;
