import styles from './Certificate.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState } from "react";

function Certificate(props) {
    const [isShowImage, setIsShowImage] = useState(false);
    const [isSize, setIsSize] = useState(false);
    const delaySetIsSize = () => {
        setTimeout(() => setIsSize(true), 50);
    }
    const delaySetIsShowImage = () => {
        setTimeout(() => setIsShowImage(false), 300);

    }

    const onKeyDown = e => {
        if (e.key === 'Escape') {
            delaySetIsShowImage();
            setIsSize(false)
        }

    }


    return (
        <div tabIndex="0" className={styles.aboutScrollBlock} onKeyDown={onKeyDown}>
            {isShowImage &&
                <div className={styles.bigImage} onClick={() => { delaySetIsShowImage(); setIsSize(false) }}>
                    <img
                        src={props.imgBig}
                        className={styles.forScaleAboutScrollBlockImage}
                        style={{ height: isSize ? '100vh' : '0px', width: isSize ? '100vw' : '0px' }}
                    />
                </div>
            }
            <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => { setIsShowImage(true); delaySetIsSize() }}>
                <img className={styles.elementWrapper} src={props.img} />
            </div>
        </div>
    );
}


export default Certificate;    
