import Content from './Content/Content';
import HeaderText from './HeaderText/HeaderText';
import styles from './Vir.module.css'
import { useState } from "react";
import PageFooter from './PageFooter/PageFooter';
import CommercialOffer from './../../../CommonComponents/CommercialOffer/CommercialOffer';

function Vir() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <CommercialOffer isOpen={isOpen} setIsOpen={setIsOpen} startProduct="Виртуальный робот" />
            <HeaderText />
            <Content setIsOpen={setIsOpen} />
            <PageFooter />
        </div>
    );
}


export default Vir;
