import CommercialOffer from '../../../../CommonComponents/CommercialOffer/CommercialOffer';
import styles from './Content.module.css'

function Content(props) {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.ProjectsWrapper}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.firstText}>
                            Программа определяет <span style={{ color: "#4A6FF5" }}>отклонение от эталонного представления</span> буквы или числа и показывает общую оценку, допускающую эти отклонения.
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Программная логика виртуального робота, в отличие от традиционных приложений позволяет обеспечить взаимодействие с пользователем и адекватно реагировать на поставляемую информацию (голосовую).
                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 120, marginBottom: 30 }}>
                <div className={styles.buyWrapper2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.lineWrapper}>
                        <div>
                            <div className={styles.textLicense} style={{ marginTop: 21, color: "#4A6FF5" }}>Стоимость приобретения для частного лица составляет: <br /></div>
                            <div className={styles.priceTextLicense} style={{ marginBottom: 21, marginTop: 10, color: "#4A6FF5" }}>2000 рублей</div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div>
                            <div className={styles.buyWrapperButton} style={{ background: "#4A6FF5", color: "#FAFAFF" }} onClick={()=>props.setIsOpen(true)}>
                                <div>Купить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 130 }}>
                <div className={styles.buyWrapper} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.lineWrapper}>
                        <div>
                            <div className={styles.textLicense} style={{ marginTop: 21 }}>Для образовательных учреждений: на 25 человек + 1 учитель<br /></div>
                            <div className={styles.priceTextLicense} style={{ marginBottom: 21, marginTop: 10 }}>60 000 рублей</div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div>
                            <div className={styles.buyWrapperButton} onClick={()=>props.setIsOpen(true)}>
                                <div>Купить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Content;
