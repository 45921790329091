import styles from './ProjectDescription.module.css'

function ProjectDescription() {
    return (
        <div className={styles.projectJustifier}>
            <div className={styles.projectDescWrapper}>
                <div className={styles.topTextBlock}>
                    <div className={styles.topTextTitle}>
                        Наши проекты
                    </div>
                    <div className={styles.topTextSubTitle}>
                        Здесь вы можете ознакомиться с самыми свежими проектами нашей компании.<br/>
                        Узнать о новых разработках и внедрениях.
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProjectDescription;
