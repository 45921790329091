import Content from './Content/Content';
import HeaderText from './HeaderText/HeaderText';
import styles from './MobiTest.module.css'

function MobiTest() {
    return (
        <div >
            <HeaderText />
            <Content />
        </div>
    );
}


export default MobiTest;
