import Content from './Content/Content';
import HeaderText from './HeaderText/HeaderText';
import styles from './ACAP.module.css'
import { useState } from "react";
import PageFooter from './PageFooter/PageFooter';
import CommercialOffer from '../../../CommonComponents/CommercialOffer/CommercialOffer';

function ACAP() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <CommercialOffer isOpen={isOpen} setIsOpen={setIsOpen} startProduct="Каллиграфическое написание" />
            <HeaderText />
            <Content setIsOpen={setIsOpen} />
            <PageFooter />
        </div>
    );
}


export default ACAP;
