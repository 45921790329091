import styles from './AchievementBlock.module.css'

function AchievementBlock(props) {
    return (
        <div className={styles.achievementBlock}>
            <div className={styles.achievementBlockHeader} style={{ marginTop: 5 }}>
                {props.header}
            </div>
            <div className={styles.achievementBlockText} style={{ marginTop: 8 }}>
                {props.text}
            </div>
        </div>
    );
}


export default AchievementBlock;
