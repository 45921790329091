import './App.css';
import {
  BrowserRouter as Router,
  Route, Routes,
} from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import MainPage from './Components/MainPage/MainPage';
import ProjectsPage from './Components/ProjectsPage/ProjectsPage';
import ProductsPage from './Components/ProductsPage/ProductsPage';
import ContactsPage from './Components/ContactsPage/ContactsPage';
import NotFound from './Components/NotFound/NotFound';
import Header from './Components/CommonComponents/Header/Header';
import Footer from './Components/Footer/Footer';
import VoiceDialogueSystem from './Components/ProjectsPage/SetOfProjects/VoiceDialogueSystem/VoiceDialogueSystem';
import RoboLogo from './Components/ProjectsPage/SetOfProjects/RoboLogo/RoboLogo';
import About from './Components/About/About';
import Workbooks14 from './Components/ProductsPage/SetOfProducts/Workbooks14/Workbooks14';
import Workbooks79 from './Components/ProductsPage/SetOfProducts/Workbooks79/Workbooks79';
import Workbooks1011 from './Components/ProductsPage/SetOfProducts/Workbooks1011/Workbooks1011';
import Vir from './Components/ProductsPage/SetOfProducts/Vir/Vir';
import ACAP from './Components/ProductsPage/SetOfProducts/ACAP/ACAP';
import MobiTest from './Components/ProjectsPage/SetOfProjects/MobiTest/MobiTest';

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  const [servicesRef, setServicesRef] = useState(null)
  return (
    <div>
      <Router>
        <div style={{ display: 'flex', justifyContent: 'center', width: "100vw" }}>
          <div style={{ width: "100vw", position: 'absolute', top: 0 }}>
            <Header servicesRef={servicesRef} />
          </div>
        </div>
        <div>
          <Routes>
            <Route exact path='/' element={<MainPage setServicesRef={setServicesRef} />}></Route>
            <Route exact path='/projects' element={<ProjectsPage />}></Route>
            <Route exact path='/products' element={<ProductsPage />}></Route>
            <Route exact path='/software_products' element={<ProductsPage />}></Route>
            <Route exact path='/contact' element={<ContactsPage />}></Route>
            <Route exact path='/about' element={<About />}></Route>
            <Route exact path='/projects/voice_dialogue_system' element={<VoiceDialogueSystem />}></Route>
            <Route exact path='/projects/robo_logo' element={<RoboLogo />}></Route>
            <Route exact path='/projects/mobitest' element={<MobiTest />}></Route>
            <Route exact path='/software_products/workbooks_1_4' element={<Workbooks14 />}></Route>
            <Route exact path='/software_products/workbooks_7_9' element={<Workbooks79 />}></Route>
            <Route exact path='/software_products/workbooks_10_11' element={<Workbooks1011 />}></Route>
            <Route exact path='/software_products/virtual_robot' element={<Vir />}></Route>
            <Route exact path='/software_products/acap' element={<ACAP />}></Route>
            <Route exact path='/404' element={<div />}></Route>
            <Route exact path='/*' element={<NotFound />}></Route>
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}


export default App;
