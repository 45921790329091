import styles from './Clients.module.css'
import axios from "axios";
import { useState } from "react";

function Clients() {
    const [email, setEmail] = useState("");
    const [isBlock, setIsBlock] = useState(false);
    const onKeyDown = e => {
        if (e.key === 'Enter')
            sendMail()
    }

    const sendMail = () => {
        alert("Ввод почты")
    }

    const sendHandler = () => {
        if (isBlock) {
            alert("Идёт отправка...")
            return
        }
        if (email === "") {
            alert("Введите вашу почту")
            return
        }
       
        setIsBlock(true)
        const request = {
            "Name": "-",
            "Email": email,
            "Phone": "-",
            "Organization": "-",
            "Product": "-",
            "Subscribe": false
        };
        axios.post("https://tkpoluslab.ru:8081/api/v1/offers/send", request)
            .then(res => {
                if (res.status === 200) {
                     setIsBlock(false)
                     setEmail("")
                     alert("Запрос успешно отправлен")
                }
            }).catch((error) => {
                alert("Ошибка отправки")
                setIsBlock(false)
            });
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 300, marginTop:-120}}>
            <div className={styles.ClientsWrapper}>
                <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                    <div className={styles.HeaderText} style={{ marginTop: 100 }}>
                        С заботой о клиентах
                    </div>
                    <div className={styles.Header2Text}>
                        Мы заинтересованы в долговременном партнерстве с каждым из наших клиентов.
                    </div>
                    <div className={styles.feedbackWrapper} style={{ marginTop: 20 }}>
                        <div className={styles.smallText}>
                            Мы разрабатываем гибкие, масштабируемые решения, развивающиеся вместе с вашей компанией, и берем на себя все обязательства долговременной поддержки и обслуживания наших систем.<br /> <br />
                            Мы всегда готовы предложить клиентам последние новинки на рынке IT-технологий, и стремимся обеспечить полную поддержку всей IT-инфраструктуры наших заказчиков.
                        </div>
                        <div className={styles.spareElement}>
                            <svg width="30" height="100%" viewBox="0 0 30 100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25.9297L29.4338 0.929688L0.56625 0.929687L12.5 25.9297L17.5 25.9297ZM12.5 165.999C12.5 167.38 13.6193 168.499 15 168.499C16.3807 168.499 17.5 167.38 17.5 165.999L12.5 165.999ZM12.5 23.4297L12.5 165.999L17.5 165.999L17.5 23.4297L12.5 23.4297Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.sendFormWrapper}>
                            <div>
                                <input
                                    value={email}
                                    className={styles.emailInputStyle}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={"Ваш Email"}
                                    onKeyDown={onKeyDown}
                                />
                            </div>
                            <div className={styles.buttonSendMail} style={{ marginTop: 23 }} onClick={() => sendHandler()}>
                                Связаться с нами
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Clients;
