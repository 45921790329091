import styles from './ProductDescription.module.css'

function ProjectDescription() {
    return (
        <div className={styles.projectJustifier}>
            <div className={styles.projectDescWrapper}>
                <div className={styles.topTextBlock}>
                    <div className={styles.topTextTitle}>     
                        Продукты
                    </div>
                    <div className={styles.topTextSubTitle}>
                        Продукты, предлагаемые нашим клиентам на заказ.<br/>
                        Здесь вы можете ознакомиться с нашим ассортиментом.
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProjectDescription;
