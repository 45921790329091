import styles from './PageFooter.module.css'
import { useState } from "react";

function PageFooter(props) {
    const [isOpen, setIsOpen] = useState(
        false
    );
    const sendMail = () => {
        props.setIsOpen(true)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 300 }}>
            <div className={styles.ClientsWrapper}>
                <div style={{ marginTop: 0, padding: "10px" }}>
                    <div className={styles.feedbackWrapper} style={{ marginTop: 20 }}>
                        <div className={styles.smallText}>
                            <div className={styles.textPrice}>
                                Стоимость рабочей тетради: <span style={{ color: '#4A6FF5' }}>1500 рублей.</span>
                            </div>
                            <div className={styles.buttonSendMail} style={{ marginTop: 23 }} onClick={() => sendMail()}>
                                Купить
                            </div>
                        </div>
                        <div className={styles.spareElement}>
                            <svg width="30" height="100%" viewBox="0 0 30 100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25.9297L29.4338 0.929688L0.56625 0.929687L12.5 25.9297L17.5 25.9297ZM12.5 165.999C12.5 167.38 13.6193 168.499 15 168.499C16.3807 168.499 17.5 167.38 17.5 165.999L12.5 165.999ZM12.5 23.4297L12.5 165.999L17.5 165.999L17.5 23.4297L12.5 23.4297Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.sendFormWrapper}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    Согласие школ на проведение апробации
                                </div>
                                <div style={{ flex: 1 }} />
                                <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
                                    <svg width="10" height="40" viewBox="0 0 10 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5" cy="5" r="5" fill="#576982" /> <circle cx="5" cy="20" r="5" fill="#576982" /> <circle cx="5" cy="35" r="5" fill="#576982" /> </svg>
                                </div>
                            </div>
                            {!isOpen &&
                                <a style={{ textDecoration: 'none' }} href={window.location.href.split(window.location.pathname)[0] + '/FymGE6bKu1SLVDh7MMbotjpyXY25R3LLrpqLuMuK85yN1x4Y7W3l4zaJsPiXLeFx.zip'}>
                                    <div className={styles.buttonDownload} style={{ marginTop: 23, textDecoration: 'none' }}>
                                        <div style={{ textDecoration: 'none' }}>Скачать</div>
                                    </div>
                                </a>
                            }

                            {isOpen &&
                                <div className={styles.buttonDownloadList} style={{ marginTop: 23 }}>
                                    <div className={styles.buttonDownloadListText} style={{ padding: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/FymGE6bKu1SLVDh7MMbotjpyXY25R3LLrpqLuMuK85yN1x4Y7W3l4zaJsPiXLeFx.zip'}>Согласие школ на проведение апробации</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/ZZm3ctYgiAk0LEw8651tslmQ3HlgWsCjYdP8T3OZipM1XjE59jdI2r0Q1gWq7RJ3.pdf'} target="_blank">Свидетельство о регистрации ПО</a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PageFooter;
