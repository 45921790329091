import styles from './Content.module.css'

function Content() {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.ProjectsWrapper}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.firstText}>
                            Наша компания ООО "Научный центр "Полюс"<span style={{ color: "#4A6FF5" }}> спроектировала и разработала по заказу </span> компании ООО "Прикладная Робототехника" программное обеспечение системы речевого диалога (СРД) на 28 млн. рублей.
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Данная работа была успешно выполнена и сдана в оговоренный срок. В процессе разработки мы продумали оптимальный способ и архитектуру реализации данного ПО заказчиком, чтобы достичь всех поставленных целей, уложиться в сроки и бюджет проекта, создав качественный и современный продукт.
                        </div>
                        <div className={styles.thirdText} style={{ marginTop: 100 }}>
                            Этапы разработки ПО Системы речевого диалога:
                        </div>

                    </div>
                </div>
            </div>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    
                </div>
            </div>
        </div>
    );
}


export default Content;
