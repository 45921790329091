import styles from './Footer.module.css'
import logo from '../../Images/logo_polus.webp'

function Footer() {
    return (
        <div className={styles.footerJustifier}>
            <div className={styles.footerHolder}>
                <div className={styles.info}>
                    <div className={styles.infoHalf}>
                        <div>
                            <img style={{userSelect:'none'}} src={logo}/>
                        </div>
                        <div className={styles.text}>
                            +7 (3822) 528-999
                        </div>
                    </div>
                    <div className={styles.infoHalf}>
                        <div className={styles.text}>
                            support@poluslab.ru
                        </div>
                        <div className={styles.text}>
                            ИНН 7017062946
                        </div>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <div className={styles.text}>
                        © 2023 Copyright. All Rights Reserved.
                    </div>
                </div>
                
            </div>
        </div>
    );
}


export default Footer;
