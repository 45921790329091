import styles from './SuccessStory.module.css'
import FSI from '../../../Images/FSI.webp';

function SuccessStory() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.ProjectsWrapper}>
                <div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="263" viewBox="0 0 30 263" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 260C12.5 261.381 13.6193 262.5 15 262.5C16.3807 262.5 17.5 261.381 17.5 260L12.5 260ZM12.5 22.5L12.5 260L17.5 260L17.5 22.5L12.5 22.5Z" fill="#CED6F0" />
                            </svg>
                        </div>
                        <div className={styles.FSIwrapper}>
                            <div>
                                <div className={styles.HeaderText}>Основана в 2002 году.</div>
                                <div className={styles.MediumText} style={{ marginTop: 30 }}>Изначально направлением деятельности была системная интеграция — проектирование, комплектация и развертывание ИТ-инфраструктуры образовательных учреждений.Тем не менее, имея опыт работы в разработке и производстве и осознавая необходимость собственных продуктов и решений для образования, уже в 2015 году НЦ «Полюс» <span style={{ color: "#4A6FF5" }}>успешно участвует в конкурсе МОСТ Фонда содействия инновациям и становится его победителем.</span></div>
                                <div className={styles.SmallText} style={{ marginTop: 20 }}>По итогам выполнения гранта было разработан первый продукт компании: система «Мобитест». Дистрибуцией «Мобитест» занимался партнер «Полюс», компания «Физикон».</div>
                            </div>
                            <div className={styles.FSI} >
                                <img src={FSI} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="243" viewBox="0 0 30 243" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 240C12.5 241.381 13.6193 242.5 15 242.5C16.3807 242.5 17.5 241.381 17.5 240L12.5 240ZM12.5 22.5L12.5 240L17.5 240L17.5 22.5L12.5 22.5Z" fill="#9FB2F3" />
                            </svg>
                        </div>
                        <div className={styles.HeaderText}>В 2018 году,</div>
                        <div className={styles.MediumText} style={{ marginTop: 30 }}>Обозначив для себя новый перспективный сегмент — робототехнику и ИИ, НЦ «Полюс» подает заявку на участие в конкурсе Развитие НТИ Фонда содействия инновациям. Заявка на выполнение НИОКР по теме “Разработка программного комплекса поддержки нейросетевых алгоритмов интеллектуальной робототехнической системы обучения и развития ребенка” <span style={{ color: "#4A6FF5" }}>была поддержана Фондом и успешно выполнена компанией.</span></div>
                        <div className={styles.SmallText} style={{ marginTop: 20 }}>По итогам НИОКР НЦ «Полюс» расширил свой продуктовый ряд, пополнив его, в частности ПО Виртуальный робот VIR (Virtual robot), ПО анализа каллиграфического написания алфавита «ACAP» (Alphabet caligraph analysis program) и образовательное ПО для социального робота Aldebaran Robotics NAO. </div>
                        <br />
                        <div className={styles.SmallText}>Тогда же был сформирован научно-технический и учебно-методический задел для развития направления «Робототехника и ИИ».</div>
                    </div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="183" viewBox="0 0 30 183" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 180C12.5 181.381 13.6193 182.5 15 182.5C16.3807 182.5 17.5 181.381 17.5 180L12.5 180ZM12.5 22.5L12.5 180L17.5 180L17.5 22.5L12.5 22.5Z" fill="#8A9FEB" />
                            </svg>

                        </div>
                        <div className={styles.HeaderText}>В 2022 году мы формируем новую заявку в ФСИ </div>
                        <div className={styles.MediumText} style={{ marginTop: 30 }}>«Облачная платформа поддержки смешанного режима преподавания и развивающего обучения в дошкольных учреждениях и начальной школе с применением подходов искусственного интеллекта». По итогам конкурса <span style={{ color: "#4A6FF5" }}>заявка НЦ «Полюс» получила грант</span> на прохождение акселерационной программы и <span style={{ color: "#4A6FF5" }}>успешно завершила ее прохождение</span> в 2023 году. </div>
                        <div className={styles.SmallText} style={{ marginTop: 20 }}>Результатом акселерации стал новый продукт «Платформа речевого развития РобоЛого», включающий в себя как облачный сервис развивающего обучения с упором на логопедию, так и социальный робот MEOW как средство доставки контента и работы с детьми.</div>
                    </div>
                    <div className={styles.BlockTextWrapper}>
                        <div className={styles.svgWrapper}>
                            <svg width="30" height="113" viewBox="0 0 30 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 110C12.5 111.381 13.6193 112.5 15 112.5C16.3807 112.5 17.5 111.381 17.5 110L12.5 110ZM12.5 22.5L12.5 110L17.5 110L17.5 22.5L12.5 22.5Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.HeaderText}>В 2022-2023 годах</div>
                        <div className={styles.MediumText} style={{ marginTop: 30 }}>Компания «Полюс» успешно реализовала <span style={{ color: "#4A6FF5" }}>собственный проект</span> по проектированию, разработке и производству цифровых лабораторий для школ, учреждений дополнительного образования, СПО и вузов «LFS PolusLab».</div>
                        <div className={styles.SmallText} style={{ marginTop: 20 }}>Продукт включает в себя цифровые мультидатчики с периферией, ПО и учебно-методическое обеспечение по предметам: «Физика», «Химия, «Биология», «Экология»,"Физиология", "Математика", "ОБЖ", "География", "Естествознание для начальных классов", "Исследование окружающей среды".</div>
                        <br />
                        <div className={styles.SmallText}>Программное обеспечение разработанное НЦ "Полюс" это "Полюс-Лаб Тест" и "Полюс-Лаб Калибровка"</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessStory;    
