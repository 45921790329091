import styles from './Stack.module.css'
import js from '../../../Images/stack/js.webp';
import python from '../../../Images/stack/python.webp';
import java from '../../../Images/stack/java.webp';
import go from '../../../Images/stack/go.webp';
import react from '../../../Images/stack/react.webp';
import php from '../../../Images/stack/php.webp';
import StackElement from './StackElement/StackElement';
import C from '../../../Images/stack/C.webp';
import Cplusplus from '../../../Images/stack/Cplusplus.webp';

function Stack() {
    return (
        <div className={styles.StackWrapper}>
            <div className={styles.headerText} style={{ marginTop: 42 }}>
                Стек технологий, на которых мы пишем
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <StackElement img={js} text={"Javascript"} />
                <StackElement img={python} text={"Python"} />
                <StackElement img={java} text={"Java"} />
                <StackElement img={go} text={"Golang"} />
                <StackElement img={react} text={"React"} />
                <StackElement img={php} text={"PHP"} />
                <StackElement img={C} text={"C"} />
                <StackElement img={Cplusplus} text={"C++"} />
            </div>
        </div>
    );
}


export default Stack;
