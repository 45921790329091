import styles from './AdaptiveMenu.module.css'
import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

function AdaptiveMenu(props) {
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => { // Открываем/Закрываем меню  
        if (props.isOpen) {
            document.documentElement.style.setProperty('--AdaptiveMenuWrapper-margin-top', '0');
        } else {
            document.documentElement.style.setProperty('--AdaptiveMenuWrapper-margin-top', '-445px');
        }
    }, [props.isOpen]);

    useEffect(() => {
        document.documentElement.style.setProperty('--ServiseElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--ProjectsElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--POElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--ProductsElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--ContactsElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--AboutElement-color', '#FAFAFF');
        document.documentElement.style.setProperty('--line-background', '#FAFAFF');
        document.documentElement.style.setProperty('--AdaptiveMenuWrapper-background', 'rgba(15, 15, 40, 0.70)');
        if (location.pathname.split('/')[1] === "projects") {
            document.documentElement.style.setProperty('--ProjectsElement-color', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "Программные продукты") {
            document.documentElement.style.setProperty('--POElement-color', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "products") {
            document.documentElement.style.setProperty('--ProductsElement-color', '#4A6FF5');
        }else if (location.pathname.split('/')[1] === "software_products") {
            document.documentElement.style.setProperty('--POElement-color', '#4A6FF5');
        }  else if (location.pathname.split('/')[1] === "about") {
            document.documentElement.style.setProperty('--AboutElement-color', '#4A6FF5');
        } else if (location.pathname.split('/')[1] === "contact") {
            document.documentElement.style.setProperty('--ServiseElement-color', '#000000');
            document.documentElement.style.setProperty('--ProjectsElement-color', '#000000');
            document.documentElement.style.setProperty('--POElement-color', '#000000');
            document.documentElement.style.setProperty('--ProductsElement-color', '#000000');
            document.documentElement.style.setProperty('--AboutElement-color', '#000000');
            document.documentElement.style.setProperty('--ContactsElement-color', '#000000');
            document.documentElement.style.setProperty('--line-background', '#000000');
            document.documentElement.style.setProperty('--ContactsElement-color', '#4A6FF5');
            document.documentElement.style.setProperty('--AdaptiveMenuWrapper-background', 'rgba(255, 255, 255, 0.70)');
        }
    }, [location.pathname.split('/')[1]]);

    return (
        <div className={styles.AdaptiveMenuWrapper}>
            <div className={styles.inWrapper}>
                <div className={styles.HeaderElement + " " + styles.ServiseElement} onClick={() => {
                    if (location.pathname.split('/')[1] === "") {
                        props.servicesRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
                    } else {
                        navigate("/", {
                            state: {
                                isService: true,
                            }
                        })
                    }
                    props.setOpen(false)
                }}>
                    Услуги
                </div>

                <div className={styles.line} />
                <div className={styles.HeaderElement + " " + styles.ProjectsElement} onClick={() => { navigate('/projects'); props.setOpen(false) }}>
                    Проекты
                </div>
                <div className={styles.line} />
                <div className={styles.HeaderElement + " " + styles.POElement} onClick={() => { navigate('/software_products'); props.setOpen(false) }}>
                    Программные продукты
                </div>
                {/* <div className={styles.line} />
                <div className={styles.HeaderElement + " " + styles.ProductsElement} onClick={() => { navigate('/products'); props.setOpen(false) }}>
                    Продукты
                </div> */}
                <div className={styles.line} />
                <div className={styles.HeaderElement + " " + styles.AboutElement} onClick={() => { navigate('/about'); props.setOpen(false) }}>
                    О нас
                </div>
                <div className={styles.line} />
                <div className={styles.HeaderElement + " " + styles.ContactsElement} onClick={() => { navigate('/contact'); props.setOpen(false) }}>
                    Контакты
                </div>
            </div>

        </div>
    );
}


export default AdaptiveMenu;
