import styles from './HeaderText.module.css'

function HeaderText() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    <div className={styles.HeaderText} style={{ marginTop: 220 }}>
                        Облако знаний: Рабочие тетради. Робототехника 7-9 классы
                    </div>
                    <div className={styles.SmallText} style={{ marginTop: 50 }}>
                        Интерактивый мультимедийный учебно-методический комплекс
                    </div>
                    <div className={styles.AdvantagesWrapper} style={{ marginTop: 60 }}>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default HeaderText;
