import Footer from '../Footer/Footer';
import ContactContainer from './ContactContainer/ContactContainer';
import ContactDescription from './ContactDescription/ContactDescription';
import ContactSocials from './ContactSocials/ContactSocials';
import styles from './ContactsPage.module.css'

function ContactsPage() {
    return (
        <div>
            <ContactDescription />
            <ContactContainer />
            {/* <ContactSocials /> */}
            <div style={{ marginTop: 100 }} />
        </div>
    );
}


export default ContactsPage;
