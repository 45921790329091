import styles from './HeaderText.module.css'

function HeaderText() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    <div className={styles.HeaderText} style={{ marginTop: 220 }}>
                        Программное обеспечение системы речевого диалога (СРД)
                    </div>
                    <div className={styles.SmallText} style={{ marginTop: 50 }}>
                        В конце 2022 года был успешно закрыт проект по заказной разработке для компании ООО "Прикладная Робототехника" на 20+ млн. рублей.
                    </div>
                    <div className={styles.AdvantagesWrapper} style={{ marginTop: 60 }}>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default HeaderText;
