import styles from './HeaderText.module.css'

function HeaderText() {
    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                    <div className={styles.HeaderText} style={{ marginTop: 220 }}>
                        Программно-аппаратный комплекс логопедической поддержки «Платформа речевого развития РобоЛого»
                    </div>
                    <div className={styles.SmallText} style={{ marginTop: 50 }}>
                        для детей дошкольного и младшего школьного возраста с использованием технологий ИИ
                    </div>
                    <div className={styles.AdvantagesWrapper} style={{ marginTop: 60 }}>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default HeaderText;
