import styles from './Content.module.css'

function Content() {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <div className={styles.ProjectsWrapper}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.catWrapper}>
                        </div>
                        <div className={styles.firstText}>
                            Компания Научный центр "Полюс" разрабатывает <span style={{ color: "#4A6FF5" }}> новое программно-инженерное решение </span> для дошкольного образования с уклоном в логопедию.
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Изделие представлено: в виде "котика", а также  разработке находиться сайт "MobiTest".
                            Обладает интерактивными функциями для взаимодействия с детьми. Как показал опыт апробации, детям не только нравится сам робот, его вид и способ взаимодействия с детьми, но и формат игр, который представлен в MEOW.
                        </div>
                        <div className={styles.thirdText} style={{ marginTop: 100 }}>
                            Возможности платформы:
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                </div>
            </div>
        </div>
    );
}


export default Content;
