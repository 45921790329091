import styles from './PageFooter.module.css'
import { useState } from "react";

function PageFooter() {
    const [isOpen, setIsOpen] = useState(
        false
    );
    const sendMail = () => {
        alert("Открытие КП")
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 300 }}>
            <div className={styles.ClientsWrapper}>
                <div style={{ marginTop: 0, padding: "10px" }}>
                    <div className={styles.feedbackWrapper} style={{ marginTop: 20 }}>
                        <div className={styles.smallText}>
                            <div className={styles.consistHeaderText}>
                                Программа предоставляется вместе с описанием алгоритмов работы в rar архиве
                            </div>
                            <div className={styles.consistElementText}>
                                Для того чтобы получить пароль в качестве <span style={{ color: "#4A6FF5" }}>демонстрации продукта</span>,<br />
                                обратитесь в отдел разработки <span style={{ color: "#4A6FF5" }}>support@tkpolus.ru</span>
                            </div>
                        </div>
                        <div className={styles.spareElement}>
                            <svg width="30" height="100%" viewBox="0 0 30 100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25.9297L29.4338 0.929688L0.56625 0.929687L12.5 25.9297L17.5 25.9297ZM12.5 165.999C12.5 167.38 13.6193 168.499 15 168.499C16.3807 168.499 17.5 167.38 17.5 165.999L12.5 165.999ZM12.5 23.4297L12.5 165.999L17.5 165.999L17.5 23.4297L12.5 23.4297Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.sendFormWrapper}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ maxWidth: 350 }}>
                                    Программа анализа каллиграфического написания алфавита с описанием работы:
                                </div>
                                <div style={{ flex: 1 }} />
                                <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
                                    <svg width="10" height="40" viewBox="0 0 10 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5" cy="5" r="5" fill="#576982" /> <circle cx="5" cy="20" r="5" fill="#576982" /> <circle cx="5" cy="35" r="5" fill="#576982" /> </svg>
                                </div>
                            </div>
                            {!isOpen &&
                                <a style={{ textDecoration: 'none' }} href={window.location.href.split(window.location.pathname)[0] + '/UkLcJmlBHj4UsyyB1TnYMqComRg89EHMuWsUBDbcRIewiECGv7cTLEXQuyMSym4D.rar'}>
                                    <div className={styles.buttonDownload} style={{ marginTop: 23, textDecoration: 'none' }}>
                                        <div style={{ textDecoration: 'none' }}>Скачать</div>
                                    </div>
                                </a>
                            }
                            {isOpen &&
                                <div className={styles.buttonDownloadList} style={{ marginTop: 23 }}>
                                    <div className={styles.buttonDownloadListText} style={{ padding: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/UkLcJmlBHj4UsyyB1TnYMqComRg89EHMuWsUBDbcRIewiECGv7cTLEXQuyMSym4D.rar'}>Программа анализа каллиграфического написания алфавита с описанием работы</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/6n7VSORPvzJKqSKErafy4janxiMwsJGzd6ProtahhqpWPmlpIy1LG5Ge4F6PYh95.pdf'} target="_blank">Мануал по установке</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ padding: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/2J1B4KLpAFyr8LD1rAT36iXtfKSHDQvh4pcpROipON9DUBnfvXolx2JqeBlhwcxR.docx'} target="_blank">Эксплуатация и сведения о ПО</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/p5f8N5BT3E6qgFeUMqQUx1RLojuLeGsJnIkyoTn6O8dld256d2WqQn0NzyhmJ3Qx.pdf'} target="_blank">Свидетельство о регистрации ПО</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ padding: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/BjdwRFaDPZFZwnT9eYFTTer3zrMgfPG8XLxB13BKRc4DyalzI0KvHoGQo5h8P4cL.docx'}>Протокол испытаний программы</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/0kGbTidQ5GOJ3vs34bmfzdpVvJOHypeQKIlKO9xAOYEcugyPuOgrfrtiDDbFKli6.docx'}>Руководство программиста</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ padding: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/ftYYDSQVJeZtAHZ7UwvAxW41xulm5NQ560upMmwG44HoOgVCxehp5Qos6bcjMnhn.docx'}>Программа и методика испытаний</a>
                                    </div>
                                    <div className={styles.buttonDownloadListText} style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 25 }}>
                                        <a className={styles.linkStyle} href={window.location.href.split(window.location.pathname)[0] + '/ot9nfWGLOeyyjTs51FvUR9dKXapn2U27ShT6voSJE5ZbC0JQMEbVTrKLZeK0VEtF.docx'}>Описание работы программы</a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PageFooter;
