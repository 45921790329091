import styles from './Certificates.module.css'
import { useRef, useEffect } from "react";
import Certificate from './Certificate/Certificate';

import msp1 from "../../../Images/SmallMainCertificates/msp1.webp";
import msp2 from "../../../Images/SmallMainCertificates/msp2.webp";
import fsi from "../../../Images/SmallMainCertificates/fsi.webp";
import year2019 from "../../../Images/SmallMainCertificates/2019.webp";
import year2018 from "../../../Images/SmallMainCertificates/2018.webp";
import polymediaCert from "../../../Images/SmallMainCertificates/polymediaCert.webp";
import licenzNaKosm from "../../../Images/SmallMainCertificates/licenzNaKosm.webp";
import certificate_1 from "../../../Images/SmallSoftwareRegistrationCertificate/1.webp";
import certificate_2 from "../../../Images/SmallSoftwareRegistrationCertificate/2.webp";
import certificate_3 from "../../../Images/SmallSoftwareRegistrationCertificate/3.webp";
import certificate_4 from "../../../Images/SmallSoftwareRegistrationCertificate/4.webp";
import certificate_5 from "../../../Images/SmallSoftwareRegistrationCertificate/5.webp";
import certificate_6 from "../../../Images/SmallSoftwareRegistrationCertificate/6.webp";
import certificate_7 from "../../../Images/SmallSoftwareRegistrationCertificate/7.webp";
import certificate_8 from "../../../Images/SmallSoftwareRegistrationCertificate/8.webp";
import certificate_9 from "../../../Images/SmallSoftwareRegistrationCertificate/9.webp";
import certificate_10 from "../../../Images/SmallSoftwareRegistrationCertificate/10.webp";
import certificate_11 from "../../../Images/SmallSoftwareRegistrationCertificate/11.webp";
import certificate_12 from "../../../Images/SmallSoftwareRegistrationCertificate/12.webp";
import polusTest from "../../../Images/SmallSoftwareRegistrationCertificate/polusTest.webp";
import polusKolibr from "../../../Images/SmallSoftwareRegistrationCertificate/polusKolibr.webp";
import smallCom from "../../../Images/SmallMainCertificates/smallCom.webp";
import sved1Small from "../../../Images/SmallMainCertificates/sved1Small.webp";
import sved2Small from "../../../Images/SmallMainCertificates/sved2Small.webp";

import msp1_bigSize from "../../../Images/MainCertificates/msp1.webp";
import msp2_bigSize from "../../../Images/MainCertificates/msp2.webp";
import fsi_bigSize from "../../../Images/MainCertificates/fsi.webp";
import year2019_bigSize from "../../../Images/MainCertificates/2019.webp";
import year2018_bigSize from "../../../Images/MainCertificates/2018.webp";
import polymediaCert_bigSize from "../../../Images/MainCertificates/polymediaCert.webp";
import licenzNaKosm_bigSize from "../../../Images/MainCertificates/licenzNaKosm.webp";
import certificate_1_bigSize from "../../../Images/SoftwareRegistrationCertificate/1.webp";
import certificate_2_bigSize from "../../../Images/SoftwareRegistrationCertificate/2.webp";
import certificate_3_bigSize from "../../../Images/SoftwareRegistrationCertificate/3.webp";
import certificate_4_bigSize from "../../../Images/SoftwareRegistrationCertificate/4.webp";
import certificate_5_bigSize from "../../../Images/SoftwareRegistrationCertificate/5.webp";
import certificate_6_bigSize from "../../../Images/SoftwareRegistrationCertificate/6.webp";
import certificate_7_bigSize from "../../../Images/SoftwareRegistrationCertificate/7.webp";
import certificate_8_bigSize from "../../../Images/SoftwareRegistrationCertificate/8.webp";
import certificate_9_bigSize from "../../../Images/SoftwareRegistrationCertificate/9.webp";
import certificate_10_bigSize from "../../../Images/SoftwareRegistrationCertificate/10.webp";
import certificate_11_bigSize from "../../../Images/SoftwareRegistrationCertificate/11.webp";
import certificate_12_bigSize from "../../../Images/SoftwareRegistrationCertificate/12.webp";
import polusTest_bigSize from "../../../Images/SoftwareRegistrationCertificate/polusTest.webp";
import polusKolibr_bigSize from "../../../Images/SoftwareRegistrationCertificate/polusKolibr.webp";
import bigCom from "../../../Images/MainCertificates/bigCom.webp";
import sved1Big from "../../../Images/MainCertificates/sved1Big.webp";
import sved2Big from "../../../Images/MainCertificates/sved2Big.webp";
import repairCert from "../../../Images/MainCertificates/repairCert.webp";
import repairCertMin from "../../../Images/MainCertificates/repairCertMin.webp";



function Certificates() {
    const scrollRef = useHorizontalScroll();
    const scrollRef2 = useHorizontalScroll();
    useEffect(() => {
        scrollRef.current.scrollTo({
            left: 70
        });
        scrollRef2.current.scrollTo({
            left: 150
        });

    }, []);


    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
            <div className={styles.ProjectsWrapper}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 80 }}>
                    <div className={styles.BlockTextWrapper}>
                        Основные сертификаты НЦ “Полюс”
                    </div>
                    <div className={styles.imagesWrapper} style={{ marginTop: 50 }}>
                        <div className={styles.imagesWrapperPartners} ref={scrollRef}>
                            <div style={{ whiteSpace: "nowrap" }}>
                                <div style={{ display: 'flex' }}>
                                    <Certificate img={sved1Small} imgBig={sved1Big} />
                                    <Certificate img={sved2Small} imgBig={sved2Big} />
                                    <Certificate img={smallCom} imgBig={bigCom} />
                                    <Certificate img={msp1} imgBig={msp1_bigSize} />
                                    <Certificate img={msp2} imgBig={msp2_bigSize} />
                                    <Certificate img={fsi} imgBig={fsi_bigSize} />
                                    <Certificate img={year2019} imgBig={year2019_bigSize} />
                                    <Certificate img={year2018} imgBig={year2018_bigSize} />
                                    <Certificate img={polymediaCert} imgBig={polymediaCert_bigSize} />
                                    <Certificate img={licenzNaKosm} imgBig={licenzNaKosm_bigSize} />
                                    <Certificate img={repairCertMin} imgBig={repairCert} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: "100%", marginTop: 50, flex: 1 }}> {/*Линия*/}
                        <div style={{ flex: 0.1 }} />
                        <div style={{ flex: 0.8, background: 'rgba(250, 250, 255, 0.20)', height: 5 }} />
                        <div style={{ flex: 0.1 }} />
                    </div>
                    <div className={styles.BlockTextWrapper}>
                        Свидетельства о регистрации ПО
                    </div>
                    <div className={styles.imagesWrapper} style={{ marginTop: 50 }}>
                        <div className={styles.imagesWrapperPartners} ref={scrollRef2}>
                            <div style={{ whiteSpace: "nowrap" }}>
                                <div style={{ display: 'flex' }}>
                                    <Certificate img={certificate_1} imgBig={certificate_1_bigSize} />
                                    <Certificate img={certificate_2} imgBig={certificate_2_bigSize} />
                                    <Certificate img={certificate_3} imgBig={certificate_3_bigSize} />
                                    <Certificate img={polusKolibr} imgBig={polusKolibr_bigSize} />
                                    <Certificate img={polusTest} imgBig={polusTest_bigSize} />
                                    <Certificate img={certificate_4} imgBig={certificate_4_bigSize} />
                                    <Certificate img={certificate_5} imgBig={certificate_5_bigSize} />
                                    <Certificate img={certificate_6} imgBig={certificate_6_bigSize} />
                                    <Certificate img={certificate_7} imgBig={certificate_7_bigSize} />
                                    <Certificate img={certificate_8} imgBig={certificate_8_bigSize} />
                                    <Certificate img={certificate_9} imgBig={certificate_9_bigSize} />
                                    <Certificate img={certificate_10} imgBig={certificate_10_bigSize} />
                                    <Certificate img={certificate_11} imgBig={certificate_11_bigSize} />
                                    <Certificate img={certificate_12} imgBig={certificate_12_bigSize} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function useHorizontalScroll() {
    const elRef = useRef();
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = e => {
                if (e.deltaY == 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + (e.deltaY * 4),
                    behavior: "smooth"
                });
            };
            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
        }
    }, []);
    return elRef;
}


export default Certificates;    
