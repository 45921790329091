import Content from './Content/Content';
import DownloaderFiles from './DownloaderFiles/DownloaderFiles';
import HeaderText from './HeaderText/HeaderText';
import styles from './RoboLogo.module.css'

function RoboLogo() {
    return (
        <div >
            <HeaderText />
            <Content />
            <DownloaderFiles />
        </div>
    );
}


export default RoboLogo;
